<template>
  <v-lazy>
    <v-card>
      <v-card-title>{{ translate("New Client") }}</v-card-title>
      <v-card-text>
        <v-form ref="first_form" lazy-validation class="v-form multi-col-validation">
          <v-row>
            <v-col>
              <v-text-field required v-model="client_name" :label="translate('Client Name')" clear-icon="shrink"
                :rules="emptyRule" outlined type="name" dense></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="phone_number" :label="translate('Phone Number')" clear-icon="shrink"
                :rules="emptyRule" outlined type="number" dense></v-text-field>
            </v-col>

            <v-col>
              <v-text-field v-model="address" :label="translate('Address')" clear-icon="shrink" outlined type="email"
                dense></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="zip_code" :label="translate('Zip code')" clear-icon="shrink" outlined
                dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="idNumber" :label="translate('ID Number')" clear-icon="shrink" :rules="emptyRule"
                outlined dense></v-text-field></v-col>
            <v-col>
              <v-select style="min-width: 150px" filled outlined :label="translate('ID Type')" dense v-model="idType"
                :items="[
                  {
                    text: 'NUIS',
                    value: 0,
                  },
                  {
                    text: 'ID Card',
                    value: 1,
                  },
                  {
                    text: 'Passport Number',
                    value: 2,
                  },
                  {
                    text: 'Number of VAT',
                    value: 3,
                  },
                  {
                    text: 'Tax Number',
                    value: 4,
                  },
                  {
                    text: 'Social Security Number',
                    value: 5,
                  },
                ]"></v-select>
            </v-col>
            <v-col>
              <v-autocomplete v-model="state_id" outlined dense label="State" :items="states" item-text="name"
                item-value="id"></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-if="state_id == 1" v-model="city_id" outlined dense label="City" :items="cities"
                item-text="city_name" item-value="city_id"></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-if="state_id == 1 && city_id != null && cities.find(stock => stock.city_id == city_id).city_areas != null"
                v-model="area_id" outlined dense label="City Area"
                :items="cities.find(stock => stock.city_id == city_id).city_areas" item-text="area_name"
                item-value="area_id"></v-autocomplete>
            </v-col>
          </v-row>
          <br />
          <l-map style="height: 500px; width: 100%" :zoom="zoom" :center="center" @click="mapclick">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="markerLatLng">
              <l-tooltip :options="{ permanent: true }">
                {{ client_name }}
              </l-tooltip>
            </l-marker>
          </l-map>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">{{
          translate("Cancel")
        }}</v-btn>
        <v-btn color="primary" @click="submit()">{{ translate("OK") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from "vue2-leaflet";
import { Icon } from "leaflet";
import { mdiGoogle } from "@mdi/js";

import axios from "axios";
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  newClient,
} from "@/ApiManager";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  props: ["cities", 'states'],

  data() {
    return {
      cities: null,
      city_id: null,
      area_id: null,
      emptyRule: [(v) => !!v || "Can't be empty!"],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
      client_name: "",
      phone_number: "",
      address: "",
      idNumber: null,
      idType: null,
      town: null,
      zip_code: null,
      city_id: null,
      state_id: null,
    };
  },
  methods: {
    async submit() {
      this.$store.state.loading = true;
      console.log(this.markerLatLng);

      var clientId = await newClient(
        this.client_name,
        this.address,
        this.markerLatLng[0],
        this.markerLatLng[1],
        this.phone_number,
        this.idNumber,
        this.idType,
        this.city_id,
        this.area_id,
        this.state_id,
        this.zip_code
      );
      this.$store.state.loading = false;
      this.$emit("closeDialog", {
        client_id: clientId,
        client_name: this.client_name,
        address: this.address,
        lat: this.markerLatLng[0],
        lng: this.markerLatLng[1],
        phone_number: this.phone_number,
        idNumber: this.idNumber,
        idType: this.idType,
        city_id: this.city_id,
        area_id: this.area_id,
        state_id: this.state_id,
        zip_code: this.zip_code,
      });
    },
    closeDialog() {
      this.$emit("closeDialog", null);
    },
    mapclick(data) {
      this.markerLatLng = [data.latlng["lat"], data.latlng["lng"]];
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
};
</script>
