var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.translate("New Client")))]),_c('v-card-text',[_c('v-form',{ref:"first_form",staticClass:"v-form multi-col-validation",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"required":"","label":_vm.translate('Client Name'),"clear-icon":"shrink","rules":_vm.emptyRule,"outlined":"","type":"name","dense":""},model:{value:(_vm.client_name),callback:function ($$v) {_vm.client_name=$$v},expression:"client_name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.translate('Phone Number'),"clear-icon":"shrink","rules":_vm.emptyRule,"outlined":"","type":"number","dense":""},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.translate('Address'),"clear-icon":"shrink","outlined":"","type":"email","dense":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.translate('Zip code'),"clear-icon":"shrink","outlined":"","dense":""},model:{value:(_vm.zip_code),callback:function ($$v) {_vm.zip_code=$$v},expression:"zip_code"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.translate('ID Number'),"clear-icon":"shrink","rules":_vm.emptyRule,"outlined":"","dense":""},model:{value:(_vm.idNumber),callback:function ($$v) {_vm.idNumber=$$v},expression:"idNumber"}})],1),_c('v-col',[_c('v-select',{staticStyle:{"min-width":"150px"},attrs:{"filled":"","outlined":"","label":_vm.translate('ID Type'),"dense":"","items":[
                {
                  text: 'NUIS',
                  value: 0,
                },
                {
                  text: 'ID Card',
                  value: 1,
                },
                {
                  text: 'Passport Number',
                  value: 2,
                },
                {
                  text: 'Number of VAT',
                  value: 3,
                },
                {
                  text: 'Tax Number',
                  value: 4,
                },
                {
                  text: 'Social Security Number',
                  value: 5,
                } ]},model:{value:(_vm.idType),callback:function ($$v) {_vm.idType=$$v},expression:"idType"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"State","items":_vm.states,"item-text":"name","item-value":"id"},model:{value:(_vm.state_id),callback:function ($$v) {_vm.state_id=$$v},expression:"state_id"}})],1),_c('v-col',[(_vm.state_id == 1)?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"City","items":_vm.cities,"item-text":"city_name","item-value":"city_id"},model:{value:(_vm.city_id),callback:function ($$v) {_vm.city_id=$$v},expression:"city_id"}}):_vm._e()],1),_c('v-col',[(_vm.state_id == 1 && _vm.city_id != null && _vm.cities.find(function (stock) { return stock.city_id == _vm.city_id; }).city_areas != null)?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"City Area","items":_vm.cities.find(function (stock) { return stock.city_id == _vm.city_id; }).city_areas,"item-text":"area_name","item-value":"area_id"},model:{value:(_vm.area_id),callback:function ($$v) {_vm.area_id=$$v},expression:"area_id"}}):_vm._e()],1)],1),_c('br'),_c('l-map',{staticStyle:{"height":"500px","width":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center},on:{"click":_vm.mapclick}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker',{attrs:{"lat-lng":_vm.markerLatLng}},[_c('l-tooltip',{attrs:{"options":{ permanent: true }}},[_vm._v(" "+_vm._s(_vm.client_name)+" ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.translate("OK")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }