<template>
  <v-lazy>
    <v-card>
      <v-card-title>{{ translate('Search for Client') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select required v-model="search_by" :label="translate('Search by')" clear-icon="shrink" :rules="emptyRule"
              outlined type="search_by" dense :items="['ID', 'Name', 'Phone Number']"></v-select>
          </v-col>
          <v-col>
            <v-text-field required v-model="text" :label="translate('Text')" clear-icon="shrink" :rules="emptyRule"
              outlined type="text" dense></v-text-field></v-col>
          <v-col>
            <v-btn color="primary" @click="submit()">{{ translate('Search') }}</v-btn>
          </v-col>
          <v-col></v-col> <v-col></v-col> <v-col></v-col>
        </v-row>
        <v-data-table :headers="headers" :items="clients" :items-per-page="20" class="table-rounded" hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" @click="selectUser(item)">{{ translate('SELECT') }}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import { mdiGoogle } from '@mdi/js'

import axios from 'axios'
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  newClient,
  searchForClients,
} from '@/ApiManager'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  data() {
    return {
      emptyRule: [v => !!v || "Can't be empty!"],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
      client_name: '',
      phone_number: '5555555',
      address: 'test address',
      search_by: 'Name',
      text: '',
      clients: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Phone Number'), value: 'phone' },
        { text: this.translate('Address'), value: 'address' },
        { text: this.translate('Actions'), value: 'actions' },
      ],
    }
  },
  methods: {
    async selectUser(user) {
      this.$emit('closeDialog', user)
    },
    async submit() {
      this.$store.state.loading = true
      var searchBy = ''
      switch (this.search_by) {
        case 'Name':
          searchBy = 'name'
          break
        case 'Phone Number':
          searchBy = 'phone'
          break
        case 'ID':
          searchBy = 'id'
          break
      }
      this.clients = await searchForClients(searchBy, this.text)
      this.$store.state.loading = false
    },
    closeDialog() {
      this.$emit('closeDialog', null)
    },
    mapclick(data) {
      this.markerLatLng = [data.latlng['lat'], data.latlng['lng']]
    },
  },
  created() {
    this.submit()
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
}
</script>